@import "user/reset"; // import reset
@import "bourbon/bourbon"; //import bourbon -- dependance for neat
@import "neat/neat"; // import neat -- grid system


// @@ animation @@
@keyframes rolly {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}


// $$ variables $$ //
// structures
$global-width: 1040px;

// colors
$site-main: #08858c;
$site-main-light: #6bb6ba;
$site-bg-title: #d3e9ea;
$site-bg-light: #e1f7f7;
$site-bg-gnav-active: #399da3;
$site-white: #ffffff;
$site-text: #686868;
$site-text-dark: #111;
$site-text-green: #08858c;
$site-border: #c0c0c0;
$site-border-light: #f4f4f5;
$site-bg-breadcrumb: #e6e6e4;
$site-border-green-light: #b2d1d3;


// %% extends and mixins @@ //

%bg_gray_tex {
  background: url(../img/bg_glaypaper.jpg) top left repeat #fafafa;
}

%needle {
  background: #fff;
  color: #d12121;
}

%nickel {
  background: #1357be;
  color: #fff;
}

@mixin btn($vp: 4px, $hp: 8px, $hover: 5.5%, $trans: .3s, $ease: linear, $radius: 0) {
  display: inline-block;
  background: $site-main;
  transition: background $trans $ease;
  border-radius: $radius;
  &:hover {
    background: lighten($site-main, $hover);
  }
  padding: 0 {
    top: $vp;
    bottom: $vp;
    left: $hp;
    right: $hp;
  }
}

@mixin pdg($vp: 4px, $hp: 8px) {
  padding: 0 {
    top: $vp;
    bottom: $vp;
    left: $hp;
    right: $hp;
  }
}

@mixin text_heading(
  $fs: 33px,
  $bl: 4px solid $site-main,
  $pl: .5em,
  $mt: 50px,
  $c: #444
  ) {
  font-size: $fs;
  border-left: $bl;
  padding-left: $pl;
  margin-top: $mt;
  color: $c;
}

@mixin label($fs: 11px, $r: 4px, $vp: 2px, $hp: 4px) {
  display: inline-block;
  margin: 0 .5em;
  font-size: $fs;
  border-radius: $r;
  @include pdg($vp,$hp);
}

@mixin table_style() {
table {
    background: #fff;
    width: 100%;
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  th,td {
    font-size: 15px;
  }
  
  tr {
    border-bottom: 1px solid $site-border-light;
    &:last-child {
      border-bottom: 0;
    }
  }
  
  th {
    color: #fff;
    background: #43a1a6;width: 106px;
  }
  
  td {
    @include pdg(20px,21px);
  }

}

// layout
@import "user/layout"; // import layout file

// toppage layout
@import "user/toppage"; // import toppage layout

// faq page layout
#faq {
  dt {
    background: $site-bg-title;
    font-size: 17px;
    color: $site-text-green;
    font-weight: bold;
    padding: 0 {
      top: 16px;
      bottom: 16px;
      left: 70px;
      right: 30px;
    }
    position: relative;
    &::before {
      content: url(../img/icon_q.png);
      position: absolute;
      top: 8px;
      left: 20px;
    }
  }
  dd {
    font-size: 17px;
    line-height: 1.5;
    margin-bottom: 58px;
    position: relative;
    padding: 0 {
      top: 22px;
      left: 70px;
      right: 30px;
      bottom: 16px;
    }

    &::before {
      content: url(../img/icon_a.png);
      position: absolute;
      top: 25px;
      left: 20px;
    }
  }
}

// recruit page layout
#rec_content {
  @extend %bg_gray_tex;
  .row > *:first-child {
    margin-bottom: 40px;
  }
  #data {
    max-width: 573px;
    float: left;
    margin-right: 35px;
    margin-bottom: 100px;
    @include table_style();
    tr {
      &:first-child {
        td {
          font-weight: bold;
        }
      }
    }
  }
}

// equipments page layout

#equip_content {
  h3 {
    text-align: center;
    margin-bottom: 40px;
  }
}

#production_equipments {
  @include clearfix;
  padding-bottom: 78px;
  margin-bottom: 78px;
  border-bottom: 1px solid #ddd;
  .lead {
    text-align: center;
    margin-bottom: 30px;
    line-height: 1.6;
  }
}

#inspection_equipments {
  margin-bottom: 112px;
  nav {
    text-align: center;
    padding-top: 56px;
    a#staff {
      @include btn(25px,63px)
    }
  }
}

.equipments_list_photo {
  margin-bottom: 15px;
  li {
    width: 320px;
    float: left;
    list-style-type: none;
    margin-bottom: 35px;
    img {
      display: block;
      margin: 0 auto 14px;
    }
    margin-right: 40px;
    &:nth-child(3n) {
      margin-right: 0;
    }
    .caption {
      color: $site-main;
      font-size: 17px;
      text-align: center;
      font-weight: bold;
    }
  }
}

#inspection_equipments {
  .equipments_list_photo {
    li {
      width: 230px;
      &:nth-child(3n) {
        margin-right: 40px;
      }
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
}

.equipments_list {
  @include span_columns(6);
  li {
    background: #fff;
    margin-bottom: 2px;
    list-style-type: none;
    @include pdg(15px,25px);
    &:last-child {
      margin-bottom: 0;
    }
    dl {
      @include clearfix;
      dt {
        float: left;
        margin-right: 5em;
      }
      dd {
        float: right;
        font-weight: bold;
        color: $site-main;
      }
    }
  }
}

// manage page layout

#manage_content {
  h3 {
    text-align: center;
    margin-bottom: 20px;
  }
  .caption {
    font-size: 14px;
    text-align: center;
    line-height: 1.5;
    margin-bottom: 35px;
  }
  .manage-section {
    margin-bottom: 30px;
    padding-bottom: 10px;
    background-color: #fff;
    &:last-child {
        margin-bottom: 60px;
    }
    header {
      padding: 20px 0;
      text-align: center;
      background-color: #08858c;
    }
    .column {
      width: 800px;
      margin: 30px auto;
      font-size: 15px;
      line-height: 1.7;
      .img-center {
        img {
          display: block;
          margin: auto;
        }
      }
      ol {
        margin-top: 40px;
        margin-bottom: 40px;
        margin-left: 26px;
        ol {
          margin-left: 40px;
          list-style-type: upper-roman;
        }
        li {
          margin: 15px 0;
        }
      }
      .iso-area {
        @include outer-container;
        margin-bottom: 60px;
        .iso-img {
          @include span-columns(6);
          img {
            display: block;
            margin: auto;
          }
        }
      }
    }
  }
}

// tech page layout

#tech_content {
  .caption {
    font-size: 14px;
    text-align: center;
    line-height: 1.5;
    margin-bottom: 35px;
  }
  .service {
    margin-bottom: 35px;
    width: 500px;
    float: left;
    margin-right: 35px;
    @include omega(even);
    
    figcaption {
      position: relative;
    }
    
    img {
      border: 1px solid $site-border-green-light;
      display: block;
      margin-bottom: 25px;
    }
    
    h3 {
      font-size: 18px;
      color: $site-main;
      margin-bottom: 15px;
    }
    
    h4 {
      font-size: 13px;
      color: $site-main;
      font-weight: normal;
      margin-bottom: 5px;
      
      & + p {
        margin-bottom: 32px;
      }
      
      
    }
    
    .desc {
      font-size: 12px;
      line-height: 1.5;
    }

    .mb {
      margin-bottom: 30px;
    }
    
    a {
      @include btn(11px,19px);
      color: #fff;
      text-decoration: none;
      font-size: 13px;
      position: absolute;
      top: 0;
      right: 0;
      &::before {
        content: url(../img/icon_button_indicator.png);
        margin-right: 5px;
      }
    }
    
  }
}

// about page layout

#about_content {
  .greeting {
    margin-bottom: 60px;
    padding: 40px 60px;
    background-color: #fff;
    h3 {
      text-align: center;
      margin-bottom: 20px;
    }
    p {
      font-size: 14px;
      line-height: 1.5;
      margin-bottom: 35px;
    }
    .president-name {
      margin-bottom: 0;
      text-align: right;
    }
  }
  .about-title {
      margin-top: 85px;
      margin-bottom: 40px;
      text-align: center;
  }
  .about-section {
    margin-bottom: 30px;
    padding-bottom: 10px;
    background-color: #fff;
    &:last-child {
        margin-bottom: 60px;
    }
    header {
      padding: 20px 0;
      text-align: center;
      background-color: #08858c;
    }
    .column {
      margin: 30px;
      font-size: 15px;
      line-height: 1.7;
      p {
        margin: 25px 0;
      }
    }
  }
  #map_canvas {
      position: relative;
      padding-bottom: 30%;
      height: 0;
      overflow: hidden;
  }
  #map_canvas iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
  }

  .large-map {
    margin-top: 10px;
    font-size: 14px;
  }
  .map-address {
      margin: 20px 0 100px;
      font-size: 14px;
  }
}

/* add shadow */
#about_content .greeting {
  position:relative;
  width: 1040px;
  margin: 0 auto 75px;
  &:before,
  &:after {
    content:"";
    position:absolute;
    z-index:-1;
    -webkit-box-shadow:0 0 20px rgba(0,0,0,0.3);
    -moz-box-shadow:0 0 20px rgba(0,0,0,0.3);
    box-shadow:0 0 20px rgba(0,0,0,0.3);
    top:50%;
    bottom:0;
    left:10px;
    right:10px;
    -moz-border-radius:100px / 30px;
    border-radius:100px / 30px;
  }
  &:after {
    right:10px;
    left:auto;
    transform:skew(8deg) rotate(3deg);
  }
}

#history {
  * {
    font-size: 14px;
    @include pdg(20px, 0);
    line-height: 2;
  }
  dt {
    font-weight: bold;
    float: left;
    width: 10em;
    position: relative;
    
    &::after {
      content: "●";
      color: $site-main;
      position: absolute;
      top: 32%;
      font-size: 120%;
      right: -0.55em;
    }
    
    &:first-child::before {
      content: "";
      display: block;
      position: absolute;
      top: 40px;
      right: 0;
      width: 1px;
//      height: 1510px;
      background: url(../img/bg_dottedline.png) top left repeat-y;
    }
    
  }
  dd {
    margin-left: 13em;
    border-top: 1px solid #ddd;
    &:last-child {
      border-bottom: 1px solid #ddd;
    }
  }
}

table.about_company {
  @include table_style();
  background: #fff;
  width: 100%;
  margin-bottom: 100px;

  th,td {
    @include pdg(20px, 40px);
    font-size: 13px;
  }
  
  th {
    min-width: 200px;
    text-align: left;
  }
  
  td {
    line-height: 1.8;
  }
  
}

#services {
  @include clearfix;
  margin-bottom: 30px;
}

#notes {
  @include pdg(27px,32px);
  border: 1px solid $site-main;
  margin-bottom: 93px;
  li {
    list-style-type: none;
    font-size: 12px;
    color: $site-main;
    margin-bottom: 13px;
    &::before {
      content: "※";
      margin-right: .5em;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

//colors
.legend {
  margin-right: -5em;
  margin-bottom: 2em;
  margin-top: 2em;
  * {
    display: inline-block;
    @include label;
    font-size: 12px;
  }
  
  .needle {
    //@extend %needle;
    color: #d30003;
    font-size: 15px;
    font-weight: bold;
  }
  
  .nickel {
    //@extend %nickel;
    color: #0049b6;
    font-size: 15px;
    font-weight: bold;
  }
  
  dd {
    margin-right: 5em;
    margin-left: 0;
    &::before {
      content: "…";
      margin-right: 1em;
    }
  }
  
}
#colors {
  margin-bottom: 80px;
  .color {
    @include span_columns(2);
    @include omega(5n);
    margin-bottom: 20px;
    width: 200px;
    margin-right: 10px;
    .fig_wrap {
      width: 200px;
      height: 200px;
      background: #fff;
      display: flex;
      -js-display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
    }
    
    figcaption {
      margin-bottom: 1em;
    }
    h4 {
      margin-bottom: .5em;
      font-weight: normal;
    }
    .desc {
      margin-bottom: 10px;
      font-size: 14px;
      min-height: 1em;
    }
    
    li {
      @include label;
      padding: 0;
      margin: 0 .5em 0 0;
      
      &.needle {
        //@extend %needle;
        color: #d30003;
        font-size: 15px;
        font-weight: bold;
      }
      
      &.nickel {
        //@extend %nickel;
        color: #0049b6;
        font-size: 15px;
        font-weight: bold;
      }
      
    }
    
  }
  
  .lb-image {
    width: 200% !important;
    height: 200% !important;
  }
  
  h3 {
    @include text_heading();
  }
  
  
}

//about

#news_content {
  h2 {
    @include text_heading();
    margin-bottom: 1.5em;
  }
  
  .topic {
    margin-bottom: 3em;
    padding-bottom: 3em;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
    dt {
      color: $site-main;
      font-weight: bold;
      width: 7em;
      float: left;
      line-height: 1.7;
    }
    dd {
      margin-left: 8em;
      line-height: 1.7;
    }
  }
  
  .topic_body {
    @include clearfix();
    display: block;
  }
  
  .topic_inner {
    float: left;
    width: 70%;
  }
  
  .photo {
    float: right;
    margin-left: 5%;
    width: 25%;
  }
  
}

/* contact */
#contact_content {
  color: #272727;
  font-size: 14px;
  min-height: 100px;
  table {
    width: 850px;
    margin: 0 auto 40px;
    tr {
      border-bottom: #000 1px dotted;
      &:first-child {
        border-top: #000 1px dotted;
      }
    }
    th {
      background: #d0e8e9;
      padding: 20px 10px;
      width: 160px;
      vertical-align: top;
    }
    td {
      padding: 15px 20px;
      background: #fff;
      vertical-align: top;
      .required {
        background: #c70101;
        color: #fff;
        font-size: 12px;
        border-radius: 3px;
        padding: 2px 5px;
        margin-right: 30px;
        vertical-align: top;
      }
      input[type="text"] {
        width: 376px;
        height: 27px;
        border: #b5b5b5 1px solid;
        margin-right: 20px;
      }
      input[type="text"].depart {
        margin-left: 70px;
      }
      input[type="text"].tel {
        width: 86px;
        margin-right: 30px;
      }
      input[type="text"].zip {
        width: 152px;
        margin-left: 70px;
      }
      input[type="text"].address {
        width: 550px;
      }
    }
  }
  .desc {
    text-align: center;
    margin-bottom: 40px;
  }
  input[type="submit"] {
    border: none;
    width: 224px;
    height: 42px;
    background: url(../img/btn_contact_submit.png) no-repeat;
    margin: 0 auto 80px;
    display: block;
  }

}