#main-slider {
  // width: 100%;
  
  .row {
    position: relative;
  }
  
  .content {
    height: 540px;
  }
  
  #content01 {
    background: url(../img/bg_slider_01.jpg) top center no-repeat #385d58;
    img {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  
  #content02 {
    background: url(../img/bg_slider_02.jpg) top center no-repeat #f5faf6;
    img {
      position: absolute;
      top: 30px;
      right: 0;
    }
  }
  
  #content03 {
    background: url(../img/bg_slider_03.jpg) top center no-repeat #000;
    img {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  
}

#mekkilist {
  padding-top: 40px;
  @extend %bg_gray_tex;

  .lead {
    text-align: center;
    margin-bottom: 36px;
    p {
      font-size: 14px;
      margin-bottom: 10px;
      color: $site-text;
    }
    
  }
  
  h2 {
    margin-bottom: 18px;
  }
  
}

#mekkilist-img {
  @include outer-container($global-width);
  @include clearfix;
  margin-bottom: -20px;
  padding-bottom: 83px;

  li {
    border: 1px solid #eaeaea;
    display: block;
    position: relative;
    float: left;
    list-style: none;
    width: 180px;
    line-height: 0;
    margin-right: 35px;
    margin-bottom: 20px;
    &:nth-child(5n) {
      margin-right: 0;
    }
    
    .caption {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.5);
      top:0;
      left: 0;
      opacity: 0;
      transition: .3s;
      display: flex;
      -js-display: flex;
      justify-content: center;
      align-items: center;
      .button {
        position: relative;
        p {
          padding: .5em;
          color: #fff;
          line-height: 1.6;
          transition: opacity 0.4s 0s ease-in-out;
          text-align: center;
        }
        &:after,
        &:before,
        p:after,
        p:before {
          background-color: #fff;
          content: '';
          display: block;
          position: absolute;
          z-index: 10;
          transition: all .3s ease;
          -webkit-transition: all .3s ease;
        }
        &:after {
          height: 2px;
          left: -1px;
          top: -1px;
          width: 0px;
        }
        &:before {
          bottom: -1px;
          height: 2px;
          right: -1px;
          width: 0px;
        }
        p:after {
          bottom: -1px;
          height: 0px;
          left: -1px;
          width: 2px;
        }
        p:before {
          height: 0px;
          right: -1px;
          top: -1px;
          width: 2px;
        }
      }
      &:hover { /* .caption:hover */
        .button {
          &:after,
          &:before {
            width: 100%;
            width: -webkit-calc(100%+2px);
            width: calc(100%+2px);
          }
          p:after,
          p:before {
            height: 100%;
            height: -webkit-calc(100%+2px);
            height: calc(100%+2px);
          }
        }
      }

      opacity: 0;
      transition: .3s;
      &:hover {
        opacity: 1;
      }
    }
    
    &:hover {
      img.ai {
        display: block;
        opacity: 1;
      }
    }
    
  }
  
}

#page-content {
  padding-top: 50px;
  padding-bottom: 50px;
  background: #c4e2e4;
  margin-top: -20px;

  nav a:hover {
    text-decoration: none;
  }
  
  .bnr {
    @include span-columns(6);
    background: #fff;
    border: 1px solid #fff;
    box-shadow: 0 0 0 1px $site-border;
    min-height: 247px;
    display: block;
    padding: 0 {
      top: 50px;
      left: 25px;
      right: 25px;
      bottom: 22px;
    }
    
    &.eco {
      padding-bottom: 21px;
      .desc {
        margin-top: -15px;
        margin-bottom: 20px;
        width: 140px;
        float: left;
      }
      .mark {
        margin-top: -15px;
        width: 100px;
        float: left;
        margin-right: 20px;
      }
      .btn {
        clear: both;
      }
      background: url(../img/bg_top_manage.jpg) top right no-repeat #fff;
    }
    
    &.recruit {
      background: url(../img/bg_top_recruit.jpg) top right no-repeat #fff;
    }
    
  }
  
  h2 {
    margin-bottom: 32px;
  }

  p {
    color: $site-text;
    font-size: 13px;
    line-height: 1.5;
  }
  
  .btn {
    @include btn(8px,28px,3.5%,.3s,linear,0);
    display: block;
    width: 127px;
  }
  
  .desc {
    margin-bottom: 28px;
  }
  
  .recruit {
    h2 {
      margin-bottom: 34px;
    }
    .desc {
      margin-bottom: 40px;
    }
  }
  
}

#news_cta {
  @extend %bg_gray_tex;
}

#news {
  padding-top: 75px;
  margin-bottom: 75px;
  h2 {
    text-align: center;
    margin-bottom: 30px;
  }
  
  ul {
    background: #fff;
    margin-bottom: 32px;
  }
  
  li {
    font-size: 15px;
    list-style-type: none;
    @include pdg(20px,20px);
    border-bottom: 2px solid $site-border-light;
    &:last-child {
      border-bottom: 0;
    }
  }
  
  time {
    color: $site-main;
    margin-right: 3em;
  }
  
  .inner {
    text-align: center;
  }
  
  .to_arch {
    @include btn(23px,78px);
    display: inline-block;
  }
  
}