// configure default css style

address {
  font-style: normal;
}

nav {
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.current {
  cursor: pointer;
}

body {
  @extend %bg_gray_tex;
}

.row {
  @include outer-container($global-width);
}

#header {
  background: #fff;
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    top: -31px;
    background: rgba(0,0,0,.08);
    position: relative;
  }

  .row {
    margin-bottom: 16px;
  }

  padding-top: 30px;

  &_logo {
    @include span-columns(6);
    a {
      transition: opacity .25s linear;
    }
    a:hover {
      opacity: .65;
    }
  }

  &_cta {
    @include span-columns(6);
    @include omega(2n);
    text-align: right;
    img {
      display: inline-block;
      vertical-align: top;
    }

    &-telnum {
      margin-right: 26px;
      margin-top: 12px;
    }

  }

  &_gnav {
    background: $site-main;

    ul {
      @include outer-container($global-width);
      line-height: 0;
      display: table;
      table-layout: fixed;
      width: 100%;
    }

    .label {
      display: inline-block;
      width: 100%;
      border-left: 1px solid $site-main-light;
      height: 32px;
    }

    li {
      display: table-cell;

      text-align: center;
      vertical-align: top;
      position: relative;
      width: calc(100%/6);

      &:last-child {
        .label {
          border-right: 1px solid $site-main-light;
        }
      }

      &.active {
        background: $site-bg-gnav-active;
      }

    }

    a {
      display: block;
      width: 100%;
      height: 100%;
      padding-top: 26px;
      padding-bottom: 26px;
      transition: background .3s linear;
      &:hover {
        background: lighten($site-main, 3.5%);
      }
    }
  }
}

#subtitle {
  padding-top: 48px;
  padding-bottom: 54px;
  background: #ddd;
  text-align: center;
  height: 210px;
}

#breadcrumb {
  background: $site-bg-breadcrumb;
  border-top: 1px solid #fff;
  margin-bottom: 37px;
  @include pdg(11px,0);
  li {
    list-style-type: none;
    font-size: 12px;
    display: inline-block;
    &::after {
      content: ">";
      margin-left: 1em;
      margin-right: 0.5em;
    }
    &:last-child::after {
      content: "";
    }
  }

  a {
    color: $site-main;
  }

}

#cta {
  display: block;
  .row {
    background: url(../img/cta_bg.jpg) no-repeat top left #fff;
    border: 1px solid $site-border-light;
    padding: 0 {
      top: 30px;
      bottom: 12px;
      left: 430px;
      right: 30px;
    }
  }

  h2 {
    margin-bottom: 10px;
  }

  address {
    margin-bottom: 15px;
  }

}

/* add shadow */
#cta {
  position:relative;
  width: 1040px;
  margin: 0 auto 75px;
  &:before,
  &:after {
    content:"";
    position:absolute;
    z-index:-1;
    -webkit-box-shadow:0 0 20px rgba(0,0,0,0.3);
    -moz-box-shadow:0 0 20px rgba(0,0,0,0.3);
    box-shadow:0 0 20px rgba(0,0,0,0.3);
    top:50%;
    bottom:0;
    left:10px;
    right:10px;
    -moz-border-radius:100px / 30px;
    border-radius:100px / 30px;
  }
  &:after {
    right:10px;
    left:auto;
    transform:skew(8deg) rotate(3deg);
  }
}

#footer {
  > .inner {
    background: $site-bg-light;
    @include pdg(45px,0);

    .row {
      @include clearfix;
    }

    .inner {
      width: 550px;
      float: left;
      @include clearfix;
    }

    img {
      display: block;
      width: 267px;
      float: left;
      margin-right: 24px;
    }

    li {
      list-style-type: none;
      font-size: 12px;
      margin-bottom: 1em;
      &:last-child {
        margin-bottom: 0;
      }
    }

  }
  &_nav {
    width: 406px;
    float: right;

    ul {
      -moz-column-count: 3;
      -webkit-column-count: 3;
      column-count: 3;
      -moz-column-width: 8em;
      -webkit-column-width: 8em;
      column-width: 8em;
      width: 470px;
    }

    li {
      padding-left: 4px;
      &::before {
        content: url(../img/icon_footer_indicator.png);
        position: relative;
        top: -1px;
        left: -4px;
      }
    }

    a {
      color: #222222;
      font-size: 12px;
    }
  }
}

#to_top {
  .row {
    position: relative;
  }
  a {
    position: absolute;
    top: -68px;
    right: -50px;

    img {
      transition: transform .3s ease-in;
    }

    &:hover {
      animation: rolly 2s linear infinite alternate;
      img {
        transform: scale(2,2)
      }
    }

  }
}

#copyright {
  background: $site-main;
  color: #fff;
  text-align: center;
  @include pdg(17px,0);
  font-size: 11px;
}

// footer ul list ie8,9 fix
.no-csscolumns {
  #footer_nav {
    position: relative;
    ul {
      position: absolute;
      top: 0;
      width:33%;
      &:first-child {
        left: 0;
      }
      &:nth-child(2) {
        left: 35%;
      }
      &:last-child {
        left: 69%;
      }
    }
  }
}
